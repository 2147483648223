<template>
  <footer
    id="FarthingStone"
    itemscope
    itemtype="https://schema.org/WPFooter"
  >
    <div class="v-container">
      <div
        class="v-row"
        :class="{ 'v-footer-show-logo': appConfig.VueSettingsPreRun.FooterShowLogo }"
      >
        <div
          v-if="appConfig.VueSettingsPreRun.FooterShowLogo"
          class="v-col-md-2 v-col-6 v-offset-3 v-offset-md-0 v-mb-md"
        >
          <div class="v-logo">
            <arora-nuxt-link
              itemscope
              :href="appConfig.VueSettingsPreRun.Links.MainLink"
              :label="translate('headerPage.homePage')"
              itemtype="https://schema.org/Brand"
            >
              <arora-nuxt-image
                :alt="translate('headerPage.homePage')"
                :image="appConfig.VueSettingsPreRun.FooterLogo"
                itemprop="logo"
              />
            </arora-nuxt-link>
          </div>
        </div>
        <div
          class="v-mb-md v-links-order"
          :class="[
            appConfig.VueSettingsPreRun.FooterEnableAppQRCode
              ? 'v-col-md-3'
              : 'v-col-md-4'
          ]"
        >
          <div
            v-if="
              !stringIsNullOrWhitespace(
                appConfig.VueSettingsPreRun.FooterExternalLinksTitle
              )
            "
            class="v-title-links v-mb-xs"
            v-html="sanitize(appConfig.VueSettingsPreRun.FooterExternalLinksTitle)"
          />
          <ul class="v-row v-links-footer--list">
            <li
              v-for="(link, index) in externalLinksFooter"
              :key="`v-links-footer-${index}`"
              class="v-col-6 v-links-footer--item v-p-0 v-mb-xs"
            >
              <arora-nuxt-link
                :external-link="!link.Url.startsWith('/')"
                :href="link.Url"
                :label="sanitize(link.Name)"
                :open-in-new-tab="!link.Url.startsWith('/')"
              />
            </li>
            <li
              v-if="appConfig.RestaurantSettingsPreRun.ContentAvailable.HasArticles"
              class="v-col-6 v-links-footer--item v-p-0 v-mb-xs"
            >
              <arora-nuxt-link
                :href="appConfig.VueSettingsPreRun.Links.ArticlesLink"
                :label="translate('footerPage.articles')"
              />
            </li>
          </ul>
          <div
            v-if="isExtraSmall"
            class="v-col-12"
          >
            <hr class="v-opacity-20" />
          </div>
        </div>
        <div
          v-if="
            !appConfig.VueSettingsPreRun.FooterShowLogo &&
            appConfig.VueSettingsPreRun.FooterShowSocial
          "
          class="v-col-md-2 v-d-flex v-flex-column v-mb-md v-social-order"
        >
          <common-social-networks
            :text="translate('footerPage.titleSocialNetworksLinks')"
          />
        </div>
        <div class="v-col-md-3 v-col-sm-6 v-mb-md v-payment-card-order">
          <common-payment-cards />
        </div>

        <div
          class="v-col-sm-6 v-mb-md v-apps-order"
          :class="[
            appConfig.VueSettingsPreRun.FooterEnableAppQRCode
              ? 'v-col-md-4'
              : 'v-col-md-3'
          ]"
        >
          <div
            v-if="appConfig.VueSettingsPreRun.FooterShowApps"
            class="v-title-apps v-subtitle v-mb-xs"
            v-html="translate('footerPage.appsDownload')"
          />
          <div class="v-row v-justify-content-end">
            <div
              v-if="isDesktopVersion"
              :class="{
                'v-col-md-4': appConfig.VueSettingsPreRun.FooterEnableAppQRCode
              }"
            >
              <img
                v-if="
                  appConfig.VueSettingsPreRun.FooterEnableAppQRCode &&
                  appConfig.VueSettingsPreRun.AppDownloadQRBase64
                "
                class="v-img-fluid"
                :alt="translate('qrPayPopup.overQr')"
                :src="`data:image/png; base64, ${appConfig.VueSettingsPreRun.AppDownloadQRBase64}`"
              />
            </div>
            <div
              :class="{
                'v-col-md-8 v-col-12':
                  appConfig.VueSettingsPreRun.FooterEnableAppQRCode
              }"
              v-if="appConfig.VueSettingsPreRun.FooterShowApps"
            >
              <common-apps />
            </div>
          </div>
        </div>

        <div class="v-col-md-5 v-mb-md v-work-time-order">
          <div
            v-if="
              appConfig.VueSettingsPreRun.FooterShowHallsSchedule ||
              appConfig.VueSettingsPreRun.FooterShowDeliverySchedule
            "
            class="v-work-time v-mb-xs"
            v-html="translate('footerPage.workTime')"
          />
          <div class="v-row v-d-flex">
            <div
              v-if="appConfig.VueSettingsPreRun.FooterShowHallsSchedule"
              class="v-col-6 v-opening-hours-hall"
              v-html="translate('footerPage.openingHoursHall')"
            />
            <div
              v-if="appConfig.VueSettingsPreRun.FooterShowDeliverySchedule"
              class="v-col-6 v-operating-hours-delivery"
              v-html="translate('footerPage.operatingHoursDelivery')"
            />
          </div>
          <div
            v-if="
              isExtraSmall &&
              (appConfig.VueSettingsPreRun.FooterShowHallsSchedule ||
                appConfig.VueSettingsPreRun.FooterShowDeliverySchedule)
            "
            class="v-col-12"
          >
            <hr class="v-opacity-20" />
          </div>
        </div>

        <div
          v-if="appConfig.VueSettingsPreRun.FooterShowLogo"
          class="v-col-md-3 v-mb-md v-offset-md-1 v-feedback-order"
          :class="{ 'v-text-center': isExtraSmall }"
        >
          <page-feedback-button
            v-if="appConfig.VueSettingsPreRun.FooterShowFeedbackButton"
            use-footer-color
          />
          <div
            v-if="appConfig.VueSettingsPreRun.FooterShowFeedbackButton"
            class="v-col-12"
          >
            <hr
              v-if="isExtraSmall"
              class="v-opacity-20"
            />
          </div>
        </div>
        <div
          v-if="
            (appConfig.VueSettingsPreRun.FooterShowSocial &&
              appConfig.VueSettingsPreRun.FooterShowLogo) ||
            (appConfig.VueSettingsPreRun.FooterShowFeedbackButton &&
              !appConfig.VueSettingsPreRun.FooterShowLogo)
          "
          class="v-col-md-3 v-mb-md v-social-order"
        >
          <div
            class="v-social-link-footer v-d-flex v-flex-column"
            :class="{
              'v-social-link-footer-right v-justify-content-end':
                appConfig.VueSettingsPreRun.FooterShowLogo && !isExtraSmall
            }"
          >
            <div :class="[isExtraSmall ? 'v-text-center' : 'v-text-right']">
              <page-feedback-button
                v-if="
                  appConfig.VueSettingsPreRun.FooterShowFeedbackButton &&
                  !appConfig.VueSettingsPreRun.FooterShowLogo
                "
                use-footer-color
              />
            </div>
            <common-social-networks
              v-if="
                appConfig.VueSettingsPreRun.FooterShowSocial &&
                appConfig.VueSettingsPreRun.FooterShowLogo
              "
              :text="translate('footerPage.titleSocialNetworksLinks')"
            />
          </div>
          <div
            v-if="isExtraSmall"
            class="v-col-12"
          >
            <hr
              v-if="
                (appConfig.VueSettingsPreRun.FooterShowSocial &&
                  appConfig.VueSettingsPreRun.FooterShowLogo) ||
                (appConfig.VueSettingsPreRun.FooterShowFeedbackButton &&
                  !appConfig.VueSettingsPreRun.FooterShowLogo)
              "
              class="v-opacity-20"
            />
          </div>
        </div>
      </div>

      <div class="v-row">
        <div class="v-col-12">
          <hr
            v-if="
              !isExtraSmall ||
              (appConfig.VueSettingsPreRun.FooterShowSocial &&
                appConfig.VueSettingsPreRun.FooterShowLogo) ||
              (appConfig.VueSettingsPreRun.FooterShowFeedbackButton &&
                !appConfig.VueSettingsPreRun.FooterShowLogo)
            "
            class="v-opacity-20"
          />
        </div>
        <div class="v-col-sm-6 v-mb-md">
          <div class="v-block-legal-professional v-d-flex">
            <div
              v-if="appConfig.VueSettingsPreRun.FooterShowCopyright"
              class="v-copyright v-mr-md"
            >
              <meta
                :content="`${appConfig.VueSettingsPreRun.CopyRightStartYear}`"
                itemprop="copyrightYear"
              />
              <meta
                v-if="appConfig.VueSettingsPreRun.CopyRightOrgName"
                :content="appConfig.VueSettingsPreRun.CopyRightOrgName"
                itemprop="copyrightHolder"
              />
              <span v-html="copyrightYears" />
            </div>
            <div class="v-privacy-politic">
              <arora-nuxt-link
                :href="appConfig.VueSettingsPreRun.Links.AgreementLink"
                :label="translate('footerPage.privacy')"
              />
            </div>
          </div>
        </div>
        <div
          class="v-col-sm-6 v-col-12 v-holder-order"
          :class="{
            'v-mb-sm': isExtraSmall
          }"
        >
          <div
            v-if="appConfig.VueSettingsPreRun.FooterShowLegalLogo"
            class="v-copyright-holder"
            :class="{
              'v-opacity-20':
                appConfig.VueSettingsPreRun.FooterLegalLogoVariant ===
                'arora-default'
            }"
          >
            <arora-nuxt-link
              class-name="v-fz-0"
              open-in-new-tab
              :href="linkSite"
              :label="linkSite"
            >
              <common-arora-logo />
            </arora-nuxt-link>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script setup lang="ts">
import type { FooterCommon } from '~types/props'

import { useCommon, useWindowSize } from '@arora/common'

defineProps<FooterCommon>()

const { isDesktopVersion, isExtraSmall } = useWindowSize()
const { sanitize, translate } = useI18nSanitized()
const { stringIsNullOrWhitespace } = useCommon()
const appConfig = useAppConfig()

const linkSite = computed<string>(() => {
  const linkSiteArora = translate('footerPage.siteArora')

  if (
    linkSiteArora.includes('arora.pro') ||
    linkSiteArora.includes('foodtech.marketing') ||
    linkSiteArora.includes('mnogo.menu')
  ) {
    return linkSiteArora
  }
  return 'https://arora.pro'
})
</script>

<style lang="scss">
@use 'assets/mixins';

#FarthingStone {
  .v-title-apps {
    font-weight: 600;
  }
  .v-title-links {
    font-size: 1.15rem;
    font-weight: 600;
  }
  .v-links-footer {
    &--list {
      list-style: none;
      margin: 0;
      padding: 0;
      .v-link {
        opacity: 0.8;
        font-size: 1rem;
      }
      .v-link {
        &:hover {
          opacity: 0.4;
        }
      }
    }
  }
  .v-social-link-footer {
    flex-wrap: wrap;
  }

  .v-social-link-footer-right {
    .v-social-text-about {
      justify-content: flex-end;
    }
  }
  .v-footer-show-logo {
    .v-links-order {
      order: 1;
      @include mixins.sm {
        order: 2;
      }
    }

    .v-payment-card-order {
      order: 2;
      @include mixins.sm {
        order: 4;
      }
    }

    .v-apps-order {
      order: 3;
      @include mixins.sm {
        order: 6;
      }
    }

    .v-work-time-order {
      order: 4;
      @include mixins.sm {
        order: 3;
      }
    }

    .v-feedback-order {
      order: 5;
      @include mixins.sm {
        order: 1;
      }
    }

    .v-social-order {
      order: 6;
      @include mixins.sm {
        order: 5;
        .v-social-link-footer {
          .v-social-networks {
            flex-direction: row;
          }
        }
      }
    }
  }
}
</style>
